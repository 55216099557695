import React from 'react';
import { Dropdown, Avatar, Menu, Button } from 'antd';
import { MenuOutlined, HomeOutlined, MobileOutlined, SettingOutlined } from '@ant-design/icons';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import styles from '../styles/AppHeader.module.css';

const AppHeader = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Función para manejar el logout
  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  // Definir los items del menú de usuario
  const userMenuItems = [
    {
      key: 'profile',
      label: 'Profile',
      onClick: () => navigate('/profile'),
    },
    {
      key: 'logout',
      label: 'Logout',
      onClick: handleLogout,
    },
  ];

  // Menú de usuario
  const userMenu = <Menu items={userMenuItems} />;

  // Definir los elementos del menú principal
  const menuItems = [
    {
      key: '/home',
      icon: <HomeOutlined style={{ fontSize: '20px' }} />,
      label: <Link to="/home" style={{ fontSize: '16px' }}>Home</Link>,
    },
    {
      key: '/devices',
      icon: <MobileOutlined style={{ fontSize: '18px' }} />,
      label: <Link to="/devices" style={{ fontSize: '16px' }}>Devices</Link>,
    },
    {
      key: '/deviceconfigs',
      icon: <SettingOutlined style={{ fontSize: '18px' }} />,
      label: <Link to="/deviceconfigs" style={{ fontSize: '16px' }}>Device Config</Link>,
    },
  ];

  return (
    <div className={styles.headerContainer}>
      {/* Botón del menú para dispositivos móviles, alineado a la izquierda */}
      <Dropdown
        overlay={
          <Menu
            theme="light"
            mode="inline"
            selectedKeys={[location.pathname]}
            style={{ borderInlineEnd: 'none' }}
            items={menuItems}
          />
        }
        trigger={['click']}
        placement="bottomLeft"
      >
        <Button
          className={styles.mobileMenuButton}
          icon={<MenuOutlined />}
        />
      </Dropdown>

      {/* Menú de usuario, alineado a la derecha */}
      <Dropdown
        overlay={userMenu}
        trigger={['click']}
        placement="bottomRight"
      >
        <div className={styles.userMenuContainer}>
          <Avatar style={{ backgroundColor: '#1890ff', marginRight: '10px' }}>
            {user?.firstname.charAt(0).toUpperCase()}
            {user?.lastname.charAt(0).toUpperCase()}
          </Avatar>
          <span>
            {user?.firstname} {user?.lastname}
          </span>
        </div>
      </Dropdown>
    </div>
  );
};

export default AppHeader;
