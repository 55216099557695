import React from 'react';
import { Layout, Typography, Collapse } from 'antd';

const { Title, Paragraph, Text } = Typography;
const { Content } = Layout;

const DocDevices = () => {
  return (
    <Content
      style={{
        padding: '24px',
        minHeight: '100vh',
        backgroundColor: '#f0f2f5', // Light background color
        color: '#000',
        borderRadius: '15px',
      }}
    >
      <Title level={1} style={{ color: '#1890ff', fontWeight: 'bold' }}>API Documentation: Devices</Title>

      <Paragraph>
        This document provides details on the API endpoints available for managing devices in the system.
      </Paragraph>

      <Title level={2} style={{ color: '#1890ff', fontWeight: 'bold' }}>Endpoints</Title>

      <Collapse
        expandIconPosition="start"
        style={{ backgroundColor: '#ffffff' }}
      >
        <Collapse.Panel header={<span style={{ fontWeight: 'bold' }}>1. Assign DeviceConfig to Devices</span>} key="1">
          <Title level={3}>Endpoint</Title>
          <Text>PUT /assignDeviceConfig</Text>
          <Title level={3}>Description</Title>
          <Paragraph>Assign a DeviceConfig to a list of devices.</Paragraph>
          <Title level={3}>Request Body</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`{
  "deviceIds": ["<deviceId1>", "<deviceId2>"],
  "deviceConfigId": "<deviceConfigId>"
}`}
            </code>
          </pre>
          <Title level={3}>Response</Title>
          <Paragraph>Returns a success message if the devices are updated successfully.</Paragraph>
          <Title level={3}>cURL Request Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`curl -X PUT "http://localhost:5000/assignDeviceConfig" -H "Content-Type: application/json" -d '{
  "deviceIds": ["deviceId1", "deviceId2"],
  "deviceConfigId": "deviceConfigId"
}'`}
            </code>
          </pre>
        </Collapse.Panel>

        <Collapse.Panel header={<span style={{ fontWeight: 'bold' }}>2. Remove DeviceConfig from Devices</span>} key="2">
          <Title level={3}>Endpoint</Title>
          <Text>PUT /removeDeviceConfig</Text>
          <Title level={3}>Description</Title>
          <Paragraph>Remove the DeviceConfig reference from a list of devices.</Paragraph>
          <Title level={3}>Request Body</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`{
  "deviceIds": ["<deviceId1>", "<deviceId2>"]
}`}
            </code>
          </pre>
          <Title level={3}>Response</Title>
          <Paragraph>Returns a success message if the DeviceConfig reference is removed successfully.</Paragraph>
          <Title level={3}>cURL Request Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`curl -X PUT "http://localhost:5000/removeDeviceConfig" -H "Content-Type: application/json" -d '{
  "deviceIds": ["deviceId1", "deviceId2"]
}'`}
            </code>
          </pre>
        </Collapse.Panel>

        <Collapse.Panel header={<span style={{ fontWeight: 'bold' }}>3. Get All Devices</span>} key="3">
          <Title level={3}>Endpoint</Title>
          <Text>GET /</Text>
          <Title level={3}>Description</Title>
          <Paragraph>Retrieve a list of all devices.</Paragraph>
          <Title level={3}>Response</Title>
          <Paragraph>Returns an array of devices.</Paragraph>
          <Title level={3}>cURL Request Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`curl -X GET "http://localhost:5000/" -H "Content-Type: application/json"`}
            </code>
          </pre>
        </Collapse.Panel>

        <Collapse.Panel header={<span style={{ fontWeight: 'bold' }}>4. Get Device by ID</span>} key="4">
          <Title level={3}>Endpoint</Title>
          <Text>GET /:id</Text>
          <Title level={3}>Description</Title>
          <Paragraph>Retrieve a single device by its ID.</Paragraph>
          <Title level={3}>Response</Title>
          <Paragraph>Returns the device with the specified ID.</Paragraph>
          <Title level={3}>cURL Request Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`curl -X GET "http://localhost:5000/deviceId" -H "Content-Type: application/json"`}
            </code>
          </pre>
        </Collapse.Panel>

        <Collapse.Panel header={<span style={{ fontWeight: 'bold' }}>5. Update Device</span>} key="5">
          <Title level={3}>Endpoint</Title>
          <Text>PUT /:id</Text>
          <Title level={3}>Description</Title>
          <Paragraph>Update the details of a device by its ID.</Paragraph>
          <Title level={3}>Request Body</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`{
  "SerialNumber": "newSerialNumber",
  "VersionString": "newVersion",
  ...
}`}
            </code>
          </pre>
          <Title level={3}>Response</Title>
          <Paragraph>Returns the updated device.</Paragraph>
          <Title level={3}>cURL Request Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`curl -X PUT "http://localhost:5000/deviceId" -H "Content-Type: application/json" -d '{
  "SerialNumber": "newSerialNumber",
  "VersionString": "newVersion",
  ...
}'`}
            </code>
          </pre>
        </Collapse.Panel>

        <Collapse.Panel header={<span style={{ fontWeight: 'bold' }}>6. Delete Device by ID</span>} key="6">
          <Title level={3}>Endpoint</Title>
          <Text>DELETE /:id</Text>
          <Title level={3}>Description</Title>
          <Paragraph>Delete a device by its ID.</Paragraph>
          <Title level={3}>Response</Title>
          <Paragraph>Returns a success message if the device is deleted.</Paragraph>
          <Title level={3}>cURL Request Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`curl -X DELETE "http://localhost:5000/deviceId" -H "Content-Type: application/json"`}
            </code>
          </pre>
        </Collapse.Panel>
      </Collapse>
    </Content>
  );
};

export default DocDevices;
