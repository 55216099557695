import React, { useState, useEffect } from 'react';
import { Modal, Input, Table, Button, message, AutoComplete, InputNumber } from 'antd';
import { SearchOutlined, SaveOutlined } from '@ant-design/icons';
import axios from '../../config/axiosInstance';

const AddEvent = ({ visible, onClose, fetchSavedEvents }) => {
  const [regasusEvents, setRegasusEvents] = useState([]);
  const [filteredRegasusEvents, setFilteredRegasusEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [host, setHost] = useState('kit-react.de'); 
  const [monthsBefore, setMonthsBefore] = useState(0); 
  const [monthsAfter, setMonthsAfter] = useState(2); 

  const autocompleteOptions = [
    { value: 'kit-react.de' },
    { value: 'ers.kit-react.de' },
    { value: 'jfr.kit-react.de' }
  ];

  const fetchRegasusEvents = async () => {
    if (!host) {
      message.error('Please select a host');
      return;
    }

    setLoading(true);
    try {
      // Get token
      const tokenResponse = await axios.get('/regasus/token', {
        params: { host }
      });
      const token = tokenResponse.data.access_token;

      // Get events
      const eventsResponse = await axios.get('/regasus/events', {
        params: { token, host },
      });

      const today = new Date();
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(today.getMonth() - monthsBefore); // Ajustar según InputNumber
      const sixMonthsAhead = new Date();
      sixMonthsAhead.setMonth(today.getMonth() + monthsAfter); // Ajustar según InputNumber

      const filteredEvents = eventsResponse.data.events.event.filter(event => {
        const eventDate = new Date(event.beginDate);
        return eventDate >= threeMonthsAgo && eventDate <= sixMonthsAhead;
      });

      const savedEventsResponse = await axios.get('/events');
      const savedEvents = savedEventsResponse.data;
console.log(savedEventsResponse.data);
      const eventsNotSaved = filteredEvents.filter(event =>
        !savedEvents.some(savedEvent => savedEvent.eventId === event.id.toString())
      );

      setRegasusEvents(eventsNotSaved);
      setFilteredRegasusEvents(eventsNotSaved);
    } catch (error) {
      message.error('Error fetching Regasus events');
      console.error('Error fetching Regasus events:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (event) => {
    setSaving(true);
    try {
      await axios.post('/events', {
        eventId: event.id.toString(),
        name: event.name[0].value,
        label: event.label[0].value,
        mnemonic: event.mnemonic,
        location: event.location,
        city: event.city,
        countryPK: event.countryPK,
        beginDate: event.beginDate,
        endDate: event.endDate
      });
      message.success('Event saved successfully');
      fetchSavedEvents();
      setRegasusEvents(regasusEvents.filter(e => e.id !== event.id));
      setFilteredRegasusEvents(filteredRegasusEvents.filter(e => e.id !== event.id));
    } catch (error) {
      console.error('Error saving event:', error.message);
      message.error('Error saving event');
    } finally {
      setSaving(false);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    const filtered = regasusEvents.filter(event =>
      event.mnemonic.toLowerCase().includes(value.toLowerCase()) ||
      event.id.toString().includes(value) ||
      event.beginDate.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredRegasusEvents(filtered);
  };

  const regasusColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Mnemonic',
      dataIndex: 'mnemonic',
      key: 'mnemonic',
    },
    {
      title: 'Begin Date',
      dataIndex: 'beginDate',
      key: 'beginDate',
      sorter: (a, b) => new Date(a.beginDate) - new Date(b.beginDate),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button
          type="primary"
          icon={<SaveOutlined />}
          onClick={() => handleSave(record)}
          loading={saving}
        >
          Save
        </Button>
      ),
    },
  ];

  return (
    <Modal
      open={visible}
      title="Add Regasus Event"
      width={1200}
      onCancel={onClose}
      footer={null}
    >
      <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center', gap: '16px' }}>
        <AutoComplete
          style={{ width: 200 }}
          options={autocompleteOptions}
          placeholder="Select host"
          value={host}
          defaultValue='kit-react.de'
          onChange={setHost}
        />
        <Button
          type="primary"
          icon={<SearchOutlined />}
          onClick={fetchRegasusEvents}
        >
          Search
        </Button>
        <InputNumber
          min={0}
          max={48}
          value={monthsBefore}
          onChange={setMonthsBefore}
          placeholder="Months Before"
        />
        <InputNumber
          min={0}
          max={24}
          value={monthsAfter}
          onChange={setMonthsAfter}
          placeholder="Months After"
        />
      </div>

      <Input
        placeholder="Search by ID, mnemonic, or begin date"
        prefix={<SearchOutlined />}
        value={searchTerm}
        onChange={handleSearch}
        style={{ marginBottom: '16px', width: '300px' }}
      />

      <Table
        columns={regasusColumns}
        dataSource={filteredRegasusEvents}
        rowKey="id"
        loading={loading}
      />
    </Modal>
  );
};

export default AddEvent;
