import React from 'react';
import { Layout, Typography, Collapse, Descriptions } from 'antd';

const { Title, Paragraph, Text } = Typography;
const { Content } = Layout;

const DocUsers = () => {
  const collapseItems = [
    {
      key: '1',
      label: <span style={{ fontWeight: 'bold' }}>1. Create a New User</span>,
      children: (
        <>
          <Title level={3}>Route</Title>
          <Text>/api/users</Text>
          <Title level={3}>Method</Title>
          <Text>POST</Text>
          <Title level={3}>Description</Title>
          <Paragraph>Creates a new user account. The password is hashed before storing.</Paragraph>
          <Title level={3}>Request Body</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>{`{
  "username": "newuser",
  "firstname": "John",
  "lastname": "Doe",
  "password": "password123"
}`}</code>
          </pre>
          <Title level={3}>Response Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>{`{
  "_id": "60d21b4667d0d8992e610c85",
  "username": "newuser",
  "firstname": "John",
  "lastname": "Doe",
  "password": "hashedpassword"
}`}</code>
          </pre>
          <Title level={3}>cURL Request Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>{`curl -X POST "http://localhost:5000/api/users" -H "Content-Type: application/json" -d '{
  "username": "newuser",
  "firstname": "John",
  "lastname": "Doe",
  "password": "password123"
}'`}</code>
          </pre>
        </>
      ),
    },
    {
      key: '2',
      label: <span style={{ fontWeight: 'bold' }}>2. Update a User</span>,
      children: (
        <>
          <Title level={3}>Route</Title>
          <Text>/api/users/:id</Text>
          <Title level={3}>Method</Title>
          <Text>PUT</Text>
          <Title level={3}>Description</Title>
          <Paragraph>Updates the details of an existing user. The password is hashed if provided.</Paragraph>
          <Title level={3}>Parameters</Title>
          <Paragraph>ID (in URL): ID of the user to update.</Paragraph>
          <Title level={3}>Request Body</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>{`{
  "username": "updateduser",
  "firstname": "Jane",
  "lastname": "Doe",
  "password": "newpassword123"
}`}</code>
          </pre>
          <Title level={3}>Response Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>{`{
  "_id": "60d21b4667d0d8992e610c85",
  "username": "updateduser",
  "firstname": "Jane",
  "lastname": "Doe",
  "password": "newhashedpassword"
}`}</code>
          </pre>
          <Title level={3}>Error Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>{`{
  "message": "User not found"
}`}</code>
          </pre>
          <Title level={3}>cURL Request Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>{`curl -X PUT "http://localhost:5000/api/users/60d21b4667d0d8992e610c85" -H "Content-Type: application/json" -d '{
  "username": "updateduser",
  "firstname": "Jane",
  "lastname": "Doe",
  "password": "newpassword123"
}'`}</code>
          </pre>
        </>
      ),
    },
    {
      key: '3',
      label: <span style={{ fontWeight: 'bold' }}>3. Get a User by ID</span>,
      children: (
        <>
          <Title level={3}>Route</Title>
          <Text>/api/users/:id</Text>
          <Title level={3}>Method</Title>
          <Text>GET</Text>
          <Title level={3}>Description</Title>
          <Paragraph>Retrieves a user by their ID.</Paragraph>
          <Title level={3}>Parameters</Title>
          <Paragraph>ID (in URL): ID of the user to retrieve.</Paragraph>
          <Title level={3}>Response Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>{`{
  "_id": "60d21b4667d0d8992e610c85",
  "username": "exampleuser",
  "firstname": "John",
  "lastname": "Doe",
  "password": "hashedpassword"
}`}</code>
          </pre>
          <Title level={3}>Error Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>{`{
  "message": "User not found"
}`}</code>
          </pre>
          <Title level={3}>cURL Request Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>{`curl -X GET "http://localhost:5000/api/users/60d21b4667d0d8992e610c85" -H "Content-Type: application/json"`}</code>
          </pre>
        </>
      ),
    },
  ];

  return (
    <Content
      style={{
        padding: '24px',
        minHeight: '100vh',
        backgroundColor: '#f0f2f5', // Light background color
        color: '#000',
        borderRadius: '15px',
      }}
    >
      <Title level={1} style={{ color: '#1890ff', fontWeight: 'bold' }}>
        API Documentation: Users
      </Title>

      <Paragraph>
        This API allows you to manage user accounts. Below are the available endpoints to perform CRUD operations on user accounts.
      </Paragraph>

      <Title level={2} style={{ color: '#1890ff', fontWeight: 'bold' }}>
        Endpoints
      </Title>

      <Collapse expandIconPosition="start" style={{ backgroundColor: '#ffffff' }} items={collapseItems} />
    </Content>
  );
};

export default DocUsers;
