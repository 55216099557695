// src/components/EventList.js
import React, { useState, useEffect } from 'react';
import axios from '../../config/axiosInstance';
import { Table, Button, Space, message, Modal, Input } from 'antd';
import { EyeOutlined, PlusOutlined, SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import AddEvent from './AddEvent';  // Importar el nuevo componente

const EventList = () => {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalViewVisible, setModalViewVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventsSearchTerm, setEventsSearchTerm] = useState('');
  const [modalAddVisible, setModalAddVisible] = useState(false);

  const fetchSavedEvents = async () => {
    setLoading(true);
    try {
      const savedEventsResponse = await axios.get('/events');
      setEvents(savedEventsResponse.data);
      setFilteredEvents(savedEventsResponse.data);
    } catch (error) {
      message.error('Error fetching saved events');
      console.error('Error fetching saved events:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSavedEvents();
  }, []);

  const handleView = async (event) => {
    try {
      const response = await axios.get(`/events/${event.eventId}`);
      setSelectedEvent(response.data);
      setModalViewVisible(true);
    } catch (error) {
      message.error('Error fetching event details');
      console.error('Error fetching event details:', error);
    }
  };

  const handleDelete = async (eventId) => {
    setLoading(true);
    try {
      await axios.delete(`/events/${eventId}`);
      message.success('Event deleted successfully');
      fetchSavedEvents();
    } catch (error) {
      console.error('Error deleting event:', error.message);
      message.error('Error deleting event');
    } finally {
      setLoading(false);
    }
  };

  const handleSearchEvents = (e) => {
    const value = e.target.value;
    setEventsSearchTerm(value);

    const filtered = events.filter(event =>
      event.name.toLowerCase().includes(value.toLowerCase()) ||
      event.eventId.toString().includes(value) ||
      event.mnemonic.toLowerCase().includes(value.toLowerCase()) ||
      event.city.toLowerCase().includes(value.toLowerCase()) ||
      event.beginDate.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredEvents(filtered);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'eventId',
      key: 'eventId',
      sorter: (a, b) => a.eventId - b.eventId,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => record.name[0]?.value || text,
    },
    {
      title: 'Mnemonic',
      dataIndex: 'mnemonic',
      key: 'mnemonic',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Begin Date',
      dataIndex: 'beginDate',
      key: 'beginDate',
      sorter: (a, b) => new Date(a.beginDate) - new Date(b.beginDate),
      render: (text) => {
        return dayjs(text).format('DD-MM-YYYY');
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            icon={<EyeOutlined />}
            onClick={() => handleView(record)}
          />
          <Button
            style={{ color: 'red' }}
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record._id)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: '24px', backgroundColor: '#ffffff' }}>
      <h1 style={{ marginBottom: '24px' }}>Event List</h1>
      <Input
        placeholder="Search by ID, name, mnemonic, city, or begin date"
        prefix={<SearchOutlined />}
        value={eventsSearchTerm}
        onChange={handleSearchEvents}
        style={{ marginBottom: '16px', width: '300px' }}
      />
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setModalAddVisible(true)}
        style={{ marginLeft: '16px', marginBottom: '16px' }}
      >
        Add Event
      </Button>
      <Table
        columns={columns}
        dataSource={filteredEvents}
        rowKey="eventId"
        loading={loading}
      />
      {selectedEvent && (
        <Modal
          open={modalViewVisible}
          title="Event Details"
          width={800}
          onCancel={() => setModalViewVisible(false)}
          footer={null}
        >
          <p><strong>ID:</strong> {selectedEvent.eventId}</p>
          <p><strong>Name:</strong> {selectedEvent.name}</p>
          <p><strong>Mnemonic:</strong> {selectedEvent.mnemonic}</p>
          <p><strong>City:</strong> {selectedEvent.city}</p>
          <p><strong>Begin Date:</strong> {selectedEvent.beginDate}</p>
          <p><strong>End Date:</strong> {selectedEvent.endDate}</p>
        </Modal>
      )}
      <AddEvent
        visible={modalAddVisible}
        onClose={() => setModalAddVisible(false)}
        fetchSavedEvents={fetchSavedEvents}
      />
    </div>
  );
};

export default EventList;
