import React, { useEffect, useState, useCallback } from 'react';
import { Form, Input, Card, Row, Col, Image, Switch, Select, message, AutoComplete } from 'antd';
import { VerticalAlignTopOutlined, VerticalAlignBottomOutlined, RetweetOutlined } from '@ant-design/icons';
import axios from '../../config/axiosInstance';

const { Option } = Select;

const DeviceConfigEditForm = ({ form, initialValues, onFinish }) => {
  const [imageUrls, setImageUrls] = useState({
    BannerUrl: '',
    ImageLeftUrl: '',
    ImageRightUrl: '',
  });

  const autocompleteOptions = [
    { value: 'kit-react.de' },
    { value: 'ers.kit-react.de' },
    { value: 'jfr.kit-react.de' }
  ];

  const [events, setEvents] = useState([]);
  const [programmePoints, setProgrammePoints] = useState([]);
  const [locations, setLocations] = useState([]);
  const [host, setHost] = useState('');
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  // Fetch events from MongoDB
  const fetchEvents = useCallback(async () => {
    try {
      const response = await axios.get('/events'); // Ajusta la ruta a la de tu API
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
      message.error('Failed to load events');
    }
  }, []);

  // Fetch event details
  const fetchEventDetails = useCallback(async (eventId, token) => {
    console.log('fetchEventDetails');
    try {
      // Fetch programme points
      const programmePointsResponse = await axios.get(`/regasus/event-pps/${eventId}`, { params: { token, host } });
      setProgrammePoints(programmePointsResponse.data.programmePoints.programmePoint || []);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        message.warning('Programme points not found for the selected event.');
      } else {
        console.error('Error fetching programme points:', error);
        message.error('Failed to load programme points');
      }
      setProgrammePoints([]); // Clear the state in case of error
    }

    try {

      if (!locations || locations.length === 0) {
        // Fetch locations
        console.log('No hay locations');
        const locationsResponse = await axios.get(`/regasus/event-locations/${eventId}`, { params: { token, host } });
        setLocations(locationsResponse.data.locations.location || []);
      }
    } catch (error) {
      if (error.response && error.response.status !== 404) {
        console.error('Error fetching locations:', error);
        message.error('Failed to load locations');
      }
      setLocations([]); // Clear the state in case of error
    }
  }, [host]);

  // Handle event selection change
  const handleEventChange = useCallback(async (eventId) => {
    setSelectedEventId(eventId);

    if (eventId) {
      try {
        const tokenResponse = await axios.get('/regasus/token', { params: { host } });
        const token = tokenResponse.data.access_token;

        const selectedEvent = events.find(event => event._id === eventId);
        if (selectedEvent && selectedEvent.eventId) {
          await fetchEventDetails(selectedEvent.eventId, token);
        }
      } catch (error) {
        console.error('Error fetching token:', error);
        message.error('Failed to fetch token');
      }
    } else {
      setProgrammePoints([]);
      setLocations([]);
    }
  }, [events, host, fetchEventDetails]);

  // Fetch events on component mount
  useEffect(() => {
    console.log('useEffect -> fetchEvents');
    fetchEvents();
  }, []);

  // Handle initialValues changes
  useEffect(() => {
    if (initialValues) {
      setHost(initialValues.Url || '');
      setImageUrls({
        BannerUrl: initialValues.BannerUrl || '',
        ImageLeftUrl: initialValues.ImageLeftUrl || '',
        ImageRightUrl: initialValues.ImageRightUrl || '',
      });
      form.setFieldsValue({
        ...initialValues,
        SelectedEventId: initialValues.SelectedEventId || null,
      });
      setSelectedEventId(initialValues.SelectedEventId || null);
    } else {
      // Reset form if no initialValues
      form.resetFields();
      setHost('');
      setImageUrls({ BannerUrl: '', ImageLeftUrl: '', ImageRightUrl: '' });
      setSelectedEventId(null);
    }
  }, [initialValues, form]);

  useEffect(() => {
    console.log('useEffect -> fetchEventDetails-programmePoints.length: ' + programmePoints.length);
    
    const fetchDetails = async () => {
      if (selectedEventId && !dataLoaded) {
        const selectedEvent = events.find(event => event._id === selectedEventId);
        
        if (selectedEvent && selectedEvent.eventId) {
          try {
            const tokenResponse = await axios.get('/regasus/token', { params: { host } });
            const token = tokenResponse.data.access_token;
  
            // Fetch programme points and locations
            await fetchEventDetails(selectedEvent.eventId, token);
            setDataLoaded(true); // Mark data as loaded
          } catch (error) {
            console.error('Error fetching initial event details:', error);
            message.error('Failed to load initial event details');
          }
        }
      }
    };
    
    fetchDetails();
  }, [selectedEventId, events, host, fetchEventDetails, dataLoaded]); // Dependencies updated
  

  // Function to handle image preview
  const handleImagePreview = (url) => (
    url ? <Image src={url} alt="preview" style={{ maxWidth: '100%', height: 'auto', marginTop: 8 }} /> : null
  );

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
    >
      <Row gutter={16} style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}>

        {/* User Information Fields */}
        <Col span={11} style={{ marginBottom: '15px' }}>
          <Card title="User Information">
            <Form.Item
              name="Username"
              label="Username"
              rules={[{ required: true, message: 'Please input the username!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="Passwort"
              label="Password"
              rules={[{ required: true, message: 'Please input the password!' }]}
            >
              <Input.Password />
            </Form.Item>
          </Card>
        </Col>

        {/* Event and URL Configuration Fields */}
        <Col span={11} style={{ marginBottom: '15px' }}>
          <Card title="Event and URL Configuration">
            <Form.Item
              name="DeviceConfigName"
              label="Device Config Name"
              rules={[{ required: true, message: 'Please input the Device Config Name!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="Url"
              label="URL"
              rules={[{ required: true, message: 'Please input the URL!' }]}
            >
              <AutoComplete
                style={{ width: 200 }}
                options={autocompleteOptions}
                placeholder="Select host"
                value={host}
                onChange={setHost}
              />
            </Form.Item>

            {/* Select for Events */}
            <Form.Item
              name="SelectedEventId"
              label="Selected Event"
            >
              <Select
                placeholder="Select an event"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={handleEventChange}
                value={selectedEventId}
                allowClear
              >
                <Option value={null} key="no-event">
                  No Event
                </Option>
                {events.length > 0 ? (
                  events.map(event => (
                    <Option key={event._id} value={event._id}>
                      {event.mnemonic}
                    </Option>
                  ))
                ) : (
                  <Option value={null} key="no-events-available" disabled>
                    No events available
                  </Option>
                )}
              </Select>
            </Form.Item>
            <Form.Item
              name="SelectedProgrammePointId"
              label="Selected Programme Point"
            >
              <Select
                placeholder="Select a programme point"
                disabled={!selectedEventId || programmePoints.length === 0}
                showSearch
                optionFilterProp="children"
              >
                {programmePoints.length > 0 ? (
                  <>
                    <Option key="no-selection" value={null}>
                      No Selection
                    </Option>
                    {programmePoints.map(point => (
                      <Option key={point.id} value={point.id}>
                        {point.name[0].value}
                      </Option>
                    ))}
                  </>
                ) : (
                  <Option value={null} key="no-points-available" disabled>
                    No Programme Points Found
                  </Option>
                )}
              </Select>
            </Form.Item>
            <Form.Item
              name="SelectedLocationId"
              label="Selected Location"
            >
              <Select
                placeholder="Select a location"
                disabled={!selectedEventId || locations.length === 0}
                showSearch
                optionFilterProp="children"
              >
                {locations.length > 0 ? (
                  locations.map(location => (
                    <Option key={location.id} value={location.id}>
                      {location.name} 
                    </Option>
                  ))
                ) : (
                  <Option value={null} key="no-location-available" disabled>
                    No Locations Found
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Card>
        </Col>

        {/* Image Fields */}
        <Col span={24} style={{ marginBottom: '15px' }}>
          <Card title="Images">
            <Row gutter={[16, 16]}>
              {/* Banner URL y Preview */}
              <Col xs={24} sm={12}>
                <Form.Item
                  name="BannerUrl"
                  label="Banner URL"
                >
                  <Input
                    value={imageUrls.BannerUrl}
                    onChange={(e) => {
                      const url = e.target.value;
                      setImageUrls(prev => ({ ...prev, BannerUrl: url }));
                      form.setFieldsValue({ BannerUrl: url });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                {handleImagePreview(imageUrls.BannerUrl)}
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              {/* Left Image URL y Preview */}
              <Col xs={24} sm={12}>
                <Form.Item
                  name="ImageLeftUrl"
                  label="Left Image URL"
                >
                  <Input
                    value={imageUrls.ImageLeftUrl}
                    onChange={(e) => {
                      const url = e.target.value;
                      setImageUrls(prev => ({ ...prev, ImageLeftUrl: url }));
                      form.setFieldsValue({ ImageLeftUrl: url });
                    }}
                  />
                </Form.Item>
                {handleImagePreview(imageUrls.ImageLeftUrl)}
              </Col>
              {/* Right Image URL y Preview */}
              <Col xs={24} sm={12}>
                <Form.Item
                  name="ImageRightUrl"
                  label="Right Image URL"
                >
                  <Input
                    value={imageUrls.ImageRightUrl}
                    onChange={(e) => {
                      const url = e.target.value;
                      setImageUrls(prev => ({ ...prev, ImageRightUrl: url }));
                      form.setFieldsValue({ ImageRightUrl: url });
                    }}
                  />
                </Form.Item>
                {handleImagePreview(imageUrls.ImageRightUrl)}
              </Col>
            </Row>
          </Card>
        </Col>

        {/* Advanced Settings Fields */}
        <Col span={24} style={{ marginBottom: '15px' }}>
          <Card title="Advanced Settings">
            <Row gutter={[16, 16]}>
              {/* Primer grupo de Switches */}
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="IsPrintBadgeAnywayEnabled"
                  valuePropName="checked"
                  label="Print Badge Anyway Enabled"
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="IsCreateLeadEnabled"
                  valuePropName="checked"
                  label="Create Lead Enabled"
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="Direction"
                  label="Direction"
                  wrapperCol={{ span: 6}}
                >
                  <Select 
                    placeholder="Select direction"
                    style={{ width: '100%' }} 
                  >
                    <Select.Option value="IN">
                      <VerticalAlignTopOutlined style={{ color: 'green', fontSize: '20px' }} /> 
                    </Select.Option>
                    <Select.Option value="OUT">
                      <VerticalAlignBottomOutlined style={{ color: 'red', fontSize: '20px' }} /> 
                    </Select.Option>
                    <Select.Option value="PASS">
                      <RetweetOutlined style={{ color: 'turquoise', fontSize: '20px' }} /> 
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              {/* Segundo grupo de Switches */}
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="IsEntryControlOnlyEnabled"
                  valuePropName="checked"
                  label="Entry Control Only Enabled"
                >
                  <Switch />
                </Form.Item>
              </Col>

              {/* Tercer grupo de Switches */}
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="CreateLeadImmediately"
                  valuePropName="checked"
                  label="Create Lead Immediately"
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="AutoCheckInMilliSeconds"
                  label="Auto Check-In Milliseconds"
                  wrapperCol={{ span: 6}}
                >
                  <Input type="number" initialValues={3000} />
                </Form.Item>
              </Col>

              {/* Cuarto grupo de Switches */}
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="IsSocketMobileEnabled"
                  valuePropName="checked"
                  label="Socket Mobile Enabled"
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="IsLineaProEnabled"
                  valuePropName="checked"
                  label="Linea Pro Enabled"
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="IsCameraEnabled"
                  valuePropName="checked"
                  label="Camera Enabled"
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default DeviceConfigEditForm;
