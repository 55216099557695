import React from 'react';
import { Layout, Typography, Collapse, Descriptions } from 'antd';

const { Title, Paragraph, Text } = Typography;
const { Content } = Layout;

const DocConfigs = () => {
  return (
    <Content
      style={{
        padding: '24px',
        minHeight: '100vh',
        backgroundColor: '#f0f2f5', // Light background color
        color: '#000',
        borderRadius: '15px',
      }}
    >
      <Title level={1} style={{ color: '#1890ff', fontWeight: 'bold' }}>API Documentation: Device Configs</Title>

      <Paragraph>
        This API allows you to manage device configurations. Below are the available endpoints to perform CRUD operations on device configurations.
      </Paragraph>

      <Title level={2} style={{ color: '#1890ff', fontWeight: 'bold' }}>Endpoints</Title>

      <Collapse
        expandIconPosition="start"
        style={{ backgroundColor: '#ffffff' }}
      >
        <Collapse.Panel header={<span style={{ fontWeight: 'bold' }}>1. Get All DeviceConfigs</span>} key="1">
          <Title level={3}>Route</Title>
          <Text>/api/deviceconfigs</Text>
          <Title level={3}>Method</Title>
          <Text>GET</Text>
          <Title level={3}>Description</Title>
          <Paragraph>Retrieves a list of all device configurations.</Paragraph>
          <Title level={3}>Response Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`[
  {
    "_id": "60d21b4667d0d8992e610c85",
    "name": "Config1",
    "description": "Description of Config1",
    "settings": { ... }
  },
  ...
]`}
            </code>
          </pre>
          <Title level={3}>cURL Request Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`curl -X GET "http://localhost:5000/api/deviceconfigs" -H "Content-Type: application/json"`}
            </code>
          </pre>
        </Collapse.Panel>

        <Collapse.Panel header={<span style={{ fontWeight: 'bold' }}>2. Get a DeviceConfig by ID</span>} key="2">
          <Title level={3}>Route</Title>
          <Text>/api/deviceconfigs/:id</Text>
          <Title level={3}>Method</Title>
          <Text>GET</Text>
          <Title level={3}>Description</Title>
          <Paragraph>Retrieves a specific device configuration by its ID.</Paragraph>
          <Title level={3}>Parameters</Title>
          <Paragraph>ID (in URL): ID of the DeviceConfig to retrieve.</Paragraph>
          <Title level={3}>Response Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`{
  "_id": "60d21b4667d0d8992e610c85",
  "name": "Config1",
  "description": "Description of Config1",
  "settings": { ... }
}`}
            </code>
          </pre>
          <Title level={3}>Error Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`{
  "message": "DeviceConfig not found"
}`}
            </code>
          </pre>
          <Title level={3}>cURL Request Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`curl -X GET "http://localhost:5000/api/deviceconfigs/60d21b4667d0d8992e610c85" -H "Content-Type: application/json"`}
            </code>
          </pre>
        </Collapse.Panel>

        <Collapse.Panel header={<span style={{ fontWeight: 'bold' }}>3. Create a New DeviceConfig</span>} key="3">
          <Title level={3}>Route</Title>
          <Text>/api/deviceconfigs</Text>
          <Title level={3}>Method</Title>
          <Text>POST</Text>
          <Title level={3}>Description</Title>
          <Paragraph>Creates a new device configuration.</Paragraph>
          <Title level={3}>Request Body</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`{
  "name": "New Config",
  "description": "Description of the new config",
  "settings": { ... }
}`}
            </code>
          </pre>
          <Title level={3}>Response Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`{
  "_id": "60d21b4667d0d8992e610c86",
  "name": "New Config",
  "description": "Description of the new config",
  "settings": { ... }
}`}
            </code>
          </pre>
          <Title level={3}>cURL Request Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`curl -X POST "http://localhost:5000/api/deviceconfigs" -H "Content-Type: application/json" -d '{
  "name": "New Config",
  "description": "Description of the new config",
  "settings": { ... }
}'`}
            </code>
          </pre>
        </Collapse.Panel>

        <Collapse.Panel header={<span style={{ fontWeight: 'bold' }}>4. Update a DeviceConfig by ID</span>} key="4">
          <Title level={3}>Route</Title>
          <Text>/api/deviceconfigs/:id</Text>
          <Title level={3}>Method</Title>
          <Text>PUT</Text>
          <Title level={3}>Description</Title>
          <Paragraph>Updates an existing device configuration by its ID.</Paragraph>
          <Title level={3}>Parameters</Title>
          <Paragraph>ID (in URL): ID of the DeviceConfig to update.</Paragraph>
          <Title level={3}>Request Body</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`{
  "name": "Updated Config",
  "description": "Updated description",
  "settings": { ... }
}`}
            </code>
          </pre>
          <Title level={3}>Response Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`{
  "_id": "60d21b4667d0d8992e610c85",
  "name": "Updated Config",
  "description": "Updated description",
  "settings": { ... }
}`}
            </code>
          </pre>
          <Title level={3}>Error Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`{
  "message": "DeviceConfig not found"
}`}
            </code>
          </pre>
          <Title level={3}>cURL Request Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`curl -X PUT "http://localhost:5000/api/deviceconfigs/60d21b4667d0d8992e610c85" -H "Content-Type: application/json" -d '{
  "name": "Updated Config",
  "description": "Updated description",
  "settings": { ... }
}'`}
            </code>
          </pre>
        </Collapse.Panel>

        <Collapse.Panel header={<span style={{ fontWeight: 'bold' }}>5. Delete a DeviceConfig by ID</span>} key="5">
          <Title level={3}>Route</Title>
          <Text>/api/deviceconfigs/:id</Text>
          <Title level={3}>Method</Title>
          <Text>DELETE</Text>
          <Title level={3}>Description</Title>
          <Paragraph>Deletes a specific device configuration by its ID.</Paragraph>
          <Title level={3}>Parameters</Title>
          <Paragraph>ID (in URL): ID of the DeviceConfig to delete.</Paragraph>
          <Title level={3}>Response Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`{
  "message": "DeviceConfig successfully deleted"
}`}
            </code>
          </pre>
          <Title level={3}>Error Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`{
  "message": "DeviceConfig not found"
}`}
            </code>
          </pre>
          <Title level={3}>cURL Request Example</Title>
          <pre style={{ backgroundColor: '#1e1e1e', color: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
            <code>
              {`curl -X DELETE "http://localhost:5000/api/deviceconfigs/60d21b4667d0d8992e610c85" -H "Content-Type: application/json"`}
            </code>
          </pre>
        </Collapse.Panel>
      </Collapse>
    </Content>
  );
};

export default DocConfigs;
