import React, { createContext, useState, useContext, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'; // Corrige el import de jwtDecode
import axiosInstance from '../config/axiosInstance'; // Importa tu instancia de axios
import BASE_URL from '../config';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        fetchUserData(decoded.userId)
          .then(userData => {
            setUser(userData);
            setIsLoading(false);
          })
          .catch(err => {
            console.error("Failed to fetch user data:", err);
            setIsLoading(false);
          });
      } catch (err) {
        console.error("Token decoding failed:", err);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, []);

  const fetchUserData = async (userId) => {
    
    try {
      const response = await axiosInstance.get(`/users/${userId}`);
      
      return response.data;
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw new Error('Failed to fetch user data');
    }
  };

  const login = async (token) => {
    localStorage.setItem('token', token);
    try {
      const decoded = jwtDecode(token);
      const userData = await fetchUserData(decoded.userId);
      
      setUser(userData);
    } catch (error) {
      console.error('Login error:', error);
      // Handle login errors as needed
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('token');
  };

  return (
    <AuthContext.Provider value={{ user, setUser, login, logout, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};
