import React, { useState, useEffect } from 'react';
import { Modal, Transfer, message } from 'antd';
import { AppleOutlined, WindowsOutlined, AndroidOutlined } from '@ant-design/icons';
import axios from '../../config/axiosInstance';

const DeviceTransferModal = ({ open, onCancel, deviceConfig, onTransferSuccess }) => { 
  const [devices, setDevices] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);

  const platformIcons = {
    iOS: <AppleOutlined style={{ fontSize: '16px', marginRight: '8px' }} />,
    MacCatalyst: <AppleOutlined style={{ fontSize: '16px', marginRight: '8px' }} />,
    WinUI: <WindowsOutlined style={{ fontSize: '16px', marginRight: '8px' }} />,
    Android: <AndroidOutlined style={{ fontSize: '16px', marginRight: '8px' }} />,
  };

  useEffect(() => {
    if (deviceConfig && open) { 
      fetchDevices();
    }
  }, [deviceConfig, open]); 

  const fetchDevices = async () => {
    try {
      const response = await axios.get(`/devices`);
      const activeDevices = response.data.filter(device => device.isActive);
      setDevices(activeDevices);
      const assignedDeviceIds = activeDevices
        .filter(device => device.DeviceConfigId && device.DeviceConfigId._id === deviceConfig._id)
        .map(device => device._id);
      setTargetKeys(assignedDeviceIds);
    } catch (error) {
      console.error('Error fetching devices:', error);
      message.error('Failed to fetch devices. Please try again.');
    }
  };

  const handleTransferChange = async (newTargetKeys) => {
    const added = newTargetKeys.filter(key => !targetKeys.includes(key));
    const removed = targetKeys.filter(key => !newTargetKeys.includes(key));

    try {
      if (added.length > 0) {
        await axios.put(`/devices/assignDeviceConfig`, {
          deviceIds: added,
          deviceConfigId: deviceConfig._id,
        });
      }

      if (removed.length > 0) {
        await axios.put(`/devices/removeDeviceConfig`, {
          deviceIds: removed,
        });
      }

      message.success('Devices updated successfully.');
      setTargetKeys(newTargetKeys);
      onTransferSuccess();
    } catch (error) {
      console.error('Error updating devices:', error);
      message.error('Error updating devices. Please try again.');
    }
  };

  return (
    <Modal
      title="Assign Devices"
      open={open} // Reemplazado 'visible' por 'open'
      onCancel={onCancel}
      footer={null}
      centered
      width={800}
      style={{ top: 20 }}
    >
      <Transfer
        dataSource={devices.map(device => ({ 
          key: device._id, 
          title: (
            <>
              {platformIcons[device.Platform]} {device.Name}
            </>
          ),
        }))}
        titles={['Available Devices', 'Assigned Devices']}
        targetKeys={targetKeys}
        onChange={handleTransferChange}
        render={item => item.title}
        style={{ width: '100%' }}
        listStyle={{
          width: 350,
          height: 400,
        }}
      />
    </Modal>
  );
};

export default DeviceTransferModal;
