import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../config/axiosInstance';
import { Table, Button, Space, message, Modal, Form, Tooltip, Popconfirm } from 'antd';
import { 
  EditOutlined, 
  DeleteOutlined, 
  MobileOutlined, 
  EyeOutlined, 
  EyeInvisibleOutlined, 
  PlusOutlined, 
  DesktopOutlined,
  SlackOutlined  // Ícono de Slack
} from '@ant-design/icons';
import DeviceConfigEditForm from './DeviceConfigEditForm';
import DeviceTransferModal from './DeviceTransferModal';
import DeviceConfigPreview from './DeviceConfigPreview';

const DeviceConfigList = () => {
  const [deviceConfigs, setDeviceConfigs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [editingDeviceConfig, setEditingDeviceConfig] = useState(null);
  const [form] = Form.useForm();
  const [showVisible, setShowVisible] = useState(true);

  const navigate = useNavigate();  // Hook de navegación

  // Estado para el modal de transferencia de dispositivos
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);

  // Estado para el modal de vista previa
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  useEffect(() => {
    fetchDeviceConfigs();
  }, [showVisible]);

  const fetchDeviceConfigs = async () => {
    try {
      const response = await axios.get(`/deviceConfigs`);
      const filteredDeviceConfigs = response.data.filter(deviceConfig => deviceConfig.IsVisible === showVisible);
      setDeviceConfigs(filteredDeviceConfigs);
    } catch (error) {
      console.error('Error fetching deviceConfigs:', error);
    }
  };

  const handleDelete = async (record) => {
    try {
      await axios.delete(`/deviceConfigs/${record._id}`);
      setDeviceConfigs(prevDeviceConfigs =>
        prevDeviceConfigs.filter(deviceConfig => deviceConfig._id !== record._id)
      );
      message.success(`DeviceConfig ${record.DeviceConfigName} deleted successfully.`);
    } catch (error) {
      message.error('Error deleting DeviceConfig. Please try again.');
    }
  };

  const handleEdit = (record) => {
    setEditingDeviceConfig(record);
    form.setFieldsValue(record);
    setIsModalOpen(true);
  };

  const handleAdd = () => {
    setEditingDeviceConfig(null);
    form.resetFields();
    setIsAddModalOpen(true);
  };

  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();

      if (editingDeviceConfig) {
        await axios.put(`/deviceConfigs/${editingDeviceConfig._id}`, values);
        message.success(`DeviceConfig ${values.DeviceConfigName} updated successfully.`);
      } else {
        await axios.post(`/deviceConfigs`, values);
        message.success(`DeviceConfig ${values.DeviceConfigName} added successfully.`);
      }

      fetchDeviceConfigs();
      setIsModalOpen(false);
      setIsAddModalOpen(false);
      setEditingDeviceConfig(null);
    } catch (error) {
      console.error('Error updating DeviceConfig:', error);
      message.error('Error updating DeviceConfig. Please try again.');
    }
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    setIsAddModalOpen(false);
    setIsPreviewModalOpen(false); // Cerrar modal de vista previa
    setEditingDeviceConfig(null);
  };

  const handleToggleVisibility = async (record) => {
    try {
      const updatedRecord = { ...record, IsVisible: !record.IsVisible };
      await axios.put(`/deviceConfigs/${record._id}`, updatedRecord);
      setDeviceConfigs(prevDeviceConfigs =>
        prevDeviceConfigs.map(deviceConfig =>
          deviceConfig._id === record._id ? updatedRecord : deviceConfig
        )
      );
      message.success(`DeviceConfig ${updatedRecord.DeviceConfigName} visibility updated.`);
    } catch (error) {
      message.error('Error updating visibility. Please try again.');
    }
  };

  const handleToggleVisibilityFilter = () => {
    setShowVisible(!showVisible);
  };

  const handleDevices = (record) => {
    setEditingDeviceConfig(record);
    setIsTransferModalOpen(true);
  };

  const handlePreview = (record) => {
    setEditingDeviceConfig(record); // Establecer el DeviceConfig actual para la vista previa
    setIsPreviewModalOpen(true); // Abrir el modal de vista previa
  };

  const handleSlack = (record) => {
    // Redirigir a la nueva ventana con las notificaciones de Slack
    navigate(`/slack-notifications/${record._id}`);
  };

  const handleTransferSuccess = () => {
    setIsTransferModalOpen(false);
    fetchDeviceConfigs();
  };

  const columns = [
    {
      title: 'Device Config Name',
      dataIndex: 'DeviceConfigName',
      key: 'DeviceConfigName',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Edit" color="blue">
            <Button icon={<EditOutlined style={{ color: 'blue' }} />} onClick={() => handleEdit(record)} />
          </Tooltip>
          <Popconfirm
            title="Are you sure you want to delete this device config?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete" color="red">
              <Button icon={<DeleteOutlined style={{ color: 'red' }}/>} />
            </Tooltip>
          </Popconfirm>
          <Tooltip title={record.IsVisible ? "Visible" : "Invisible"} color={record.IsVisible ? "green" : "gray"}>
            <Button
              icon={record.IsVisible ? <EyeOutlined style={{ color: 'green' }} /> : <EyeInvisibleOutlined style={{ color: 'gray' }} />}
              onClick={() => handleToggleVisibility(record)}
            />
          </Tooltip>
          <Tooltip title="Devices" color="purple">
            <Button icon={<MobileOutlined style={{ color: 'purple' }}/>} onClick={() => handleDevices(record)} />
          </Tooltip>
          <Tooltip title="Preview" color="orange">
            <Button icon={<DesktopOutlined style={{ color: 'orange' }} />} onClick={() => handlePreview(record)} />
          </Tooltip>
          <Tooltip title="Slack" color="#611f69">
            <Button 
              icon={<SlackOutlined style={{ color: '#611f69' }}/>} 
              onClick={() => handleSlack(record)} 
              style={{ marginLeft: '10px' }}  // Añadir margen izquierdo de 10px
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const tableHeaderExtra = (
    <>
      <Button
        icon={showVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
        onClick={handleToggleVisibilityFilter}
        style={{ marginBottom: '16px' }}
      >
        {showVisible ? 'Show Hidden' : 'Show Visible'}
      </Button>
      <Button
        icon={<PlusOutlined />}
        type="primary"
        onClick={handleAdd}
        style={{ marginBottom: '16px', marginLeft: '8px' }}
      >
        Add New
      </Button>
    </>
  );

  return (
    <div style={{ padding: '24px', backgroundColor: '#ffffff' }}>
      <h1 style={{ marginBottom: '24px' }}>Device Configs List</h1>
      <Table
        columns={columns}
        dataSource={deviceConfigs}
        rowKey="_id"
        title={() => tableHeaderExtra}
      />

      {/* Modal para Editar y Agregar DeviceConfig */}
      <Modal
        title={editingDeviceConfig ? "Edit DeviceConfig" : "Add New DeviceConfig"}
        open={isModalOpen || isAddModalOpen}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Save"
        cancelText="Cancel"
        width="80%"
        style={{ top: 20 }}
        destroyOnClose
      >
        <DeviceConfigEditForm
          key={editingDeviceConfig ? editingDeviceConfig._id : 'new'}
          form={form}
          initialValues={editingDeviceConfig}
          onFinish={handleModalOk}
        />
      </Modal>

      {/* Otros Modals */}
      <DeviceTransferModal
        open={isTransferModalOpen}
        onCancel={() => setIsTransferModalOpen(false)}
        deviceConfig={editingDeviceConfig}
        onTransferSuccess={handleTransferSuccess}
      />

      <DeviceConfigPreview
        open={isPreviewModalOpen}
        onCancel={handleModalCancel}
        deviceConfig={editingDeviceConfig}  // Pasar la configuración al modal de vista previa
      />
    </div>
  );
};

export default DeviceConfigList;
