import React, { useState, useEffect } from 'react';
import axios from '../../config/axiosInstance';
import { Table, Button, Space, Card, Row, Col, Modal, Form, message, Tooltip, Popconfirm, Input } from 'antd';
import { EditOutlined, LockOutlined as LockOutlinedRegular, UnlockOutlined, DeleteOutlined, AppleOutlined, WindowsOutlined, AndroidOutlined } from '@ant-design/icons';
import DeviceEditForm from './DeviceEditForm';
import '../../styles/DeviceList.css'; // Importa el archivo CSS

const { Meta } = Card;
const { Search } = Input; // Asegúrate de extraer Search de Input

const DeviceList = () => {
  const [devices, setDevices] = useState([]);
  const [deviceCounts, setDeviceCounts] = useState({
    iOS: 0,
    Android: 0,
    WinUI: 0, // Agregado para WinUI
  });
  const [selectedPlatforms, setSelectedPlatforms] = useState(new Set());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingDevice, setEditingDevice] = useState(null);
  const [form] = Form.useForm(); // Define el objeto form usando Form.useForm()
  const [searchText, setSearchText] = useState('');
  const [foundDevices, setFoundDevices] = useState([]);

  useEffect(() => {
    axios.get('/devices')
      .then(response => {
        const data = response.data;
        setDevices(data);

        // Contar dispositivos por plataforma
        const counts = data.reduce((acc, device) => {
          const platform = device.Platform;
          if (platform === 'MacCatalyst') {
            acc.iOS = (acc.iOS || 0) + 1;
          } else {
            acc[platform] = (acc[platform] || 0) + 1;
          }
          return acc;
        }, {});

        // Establecer los recuentos en el estado
        setDeviceCounts({
          iOS: counts.iOS || 0,
          Android: counts.Android || 0,
          WinUI: counts.WinUI || 0, // Agregado para WinUI
        });
      })
      .catch(error => {
        console.error('Hubo un error al obtener los dispositivos:', error);
      });
  }, []);

  useEffect(() => {
    const lowerCaseSearchText = searchText.toLowerCase();
    const filtered = devices.filter(device => 
      device.Name.toLowerCase().includes(lowerCaseSearchText) ||
      (device.DeviceConfigId?.DeviceConfigName || '').toLowerCase().includes(lowerCaseSearchText) ||
      device.SerialNumber.toLowerCase().includes(lowerCaseSearchText) ||
      device.Platform.toLowerCase().includes(lowerCaseSearchText)
    );
    setFoundDevices(filtered);
  }, [searchText, devices]);

  // Mapea los nombres de las plataformas a los íconos correspondientes
  const platformIcons = {
    iOS: <AppleOutlined style={{ fontSize: '24px' }} />,
    Android: <AndroidOutlined style={{ fontSize: '24px' }} />,
    WinUI: <WindowsOutlined style={{ fontSize: '24px' }} />,
    MacCatalyst: <AppleOutlined style={{ fontSize: '24px' }} />, // Usa el ícono de Apple para MacCatalyst
  };

  // Maneja la selección de plataformas
  const handlePlatformClick = (platform) => {
    setSelectedPlatforms(prev => {
      const updated = new Set(prev);
      if (updated.has(platform)) {
        updated.delete(platform);
      } else {
        updated.add(platform);
      }
      return updated;
    });
  };

  // Filtrar los dispositivos según las plataformas seleccionadas
  const filteredDevices = foundDevices.filter(device => 
    selectedPlatforms.size === 0 || selectedPlatforms.has(device.Platform) || (device.Platform === 'MacCatalyst' && selectedPlatforms.has('iOS'))
  );

  // Define las columnas de la tabla
  const columns = [
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: 'Serial Number',
      dataIndex: 'SerialNumber',
      key: 'SerialNumber',
    },
    {
      title: 'Platform',
      dataIndex: 'Platform',
      key: 'Platform',
      render: (platform) => (
        <span>{platformIcons[platform] || 'Unknown'}</span>
      ),
    },
    {
      title: 'Device Config Name',
      dataIndex: ['DeviceConfigId', 'DeviceConfigName'], // Accede a configName dentro de DeviceConfigId
      key: 'DeviceConfigName',
      sorter: (a, b) => {
        const configNameA = a.DeviceConfigId?.DeviceConfigName || '';
        const configNameB = b.DeviceConfigId?.DeviceConfigName || '';
        return configNameA.localeCompare(configNameB);
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Edit" color="blue">
            <Button icon={<EditOutlined />} onClick={() => handleEdit(record)} />
          </Tooltip>
          <Tooltip title={record.isActive ? "Locked" : "Unlocked"} color={record.isActive ? "orange" : "red"}>
            <Button 
              icon={record.isActive ? <UnlockOutlined /> : <LockOutlinedRegular style={{ color: 'red' }} />} 
              onClick={() => handleLock(record)} 
            />
          </Tooltip>
          <Popconfirm
            title="Are you sure you want to delete this device?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete" color="red">
              <Button 
                icon={<DeleteOutlined />} 
              />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // Maneja los eventos de los botones
  const handleEdit = (record) => {
    setEditingDevice(record);
    form.setFieldsValue(record); // Establece los valores del formulario con el dispositivo seleccionado
    setIsModalOpen(true); // Abre el modal
  };

  const handleLock = async (record) => {
    try {
      // Alterna el estado activo
      const newActiveState = !record.isActive;
  
      // Define los valores que se actualizarán
      const updatedFields = {
        isActive: newActiveState,
        DeviceConfigId: newActiveState ? record.DeviceConfigId : null, // Si se desactiva, establecer a null
        DeviceConfigName: newActiveState ? record.DeviceConfigName : null, // Si se desactiva, establecer a null
      };
  
      // Realiza la solicitud para actualizar el dispositivo
      await axios.put(`/devices/${record._id}`, updatedFields);
  
      // Actualiza el estado local después de la solicitud
      setDevices(prevDevices =>
        prevDevices.map(device =>
          device._id === record._id 
            ? { ...device, ...updatedFields } 
            : device
        )
      );
  
      // Muestra un mensaje de éxito
      message.success(`Device ${record.Name} has been ${newActiveState ? 'unlocked' : 'locked'} successfully.`);
    } catch (error) {
      // Muestra un mensaje de error
      message.error('Error updating device active state. Please try again.');
    }
  };  

  const handleDelete = async (record) => {
    try {
      await axios.delete(`/devices/${record._id}`);
      
      // Actualiza el estado local después de eliminar el dispositivo
      setDevices(prevDevices => prevDevices.filter(device => device._id !== record._id));
      
      // Muestra un mensaje de éxito
      message.success(`Device ${record.Name} has been deleted successfully.`);
    } catch (error) {
      // Muestra un mensaje de error
      message.error('Error deleting device. Please try again.');
    }
  };  

  const handleCancel = () => {
    setIsModalOpen(false); // Cierra el modal
    setEditingDevice(null);
  };

  const handleSave = async (values) => {
    try {
      const response = await axios.put(`/devices/${editingDevice._id}`, values);
      console.log('Device updated successfully', response.data);
      setDevices(prevDevices =>
        prevDevices.map(device =>
          device._id === editingDevice._id ? { ...device, ...values } : device
        )
      );
      setIsModalOpen(false); // Cierra el modal
      setEditingDevice(null);
    } catch (error) {
      console.error('Error updating device:', error);
    }
  };

  return (
    <div style={{ padding: '24px', backgroundColor: '#ffffff' }}>
      <h1 style={{ marginBottom: '24px' }}>Devices List</h1>
      {/* Tarjetas de plataformas */}
      <Row gutter={16} style={{ marginBottom: '48px' }}> {/* Aumenta la separación entre el título y las tarjetas */}
        <Col span={8}>
          <Card 
            bordered={false} 
            className={`platform-card ${selectedPlatforms.has('iOS') ? 'selected' : ''}`}
            onClick={() => handlePlatformClick('iOS')}
          >
            <div className="platform-card-content">
              <AppleOutlined 
                className="platform-icon" 
                style={{ 
                  fontSize: '48px', 
                  color: '#555555', 
                  stroke: '#555555', 
                  strokeWidth: 0.5 
                }} 
              />
              <Meta title="Apple" description={`Devices: ${deviceCounts.iOS || 0}`} className="platform-meta" />
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card 
            bordered={false} 
            className={`platform-card ${selectedPlatforms.has('WinUI') ? 'selected' : ''}`}
            onClick={() => handlePlatformClick('WinUI')}
          >
            <div className="platform-card-content">
              <WindowsOutlined 
                className="platform-icon" 
                style={{ 
                  fontSize: '48px', 
                  color: '#0078D6', 
                  stroke: '#0078D6', 
                  strokeWidth: 0.5 
                }} 
              />
              <Meta title="Windows" description={`Devices: ${deviceCounts.WinUI || 0}`} className="platform-meta" />
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card 
            bordered={false} 
            className={`platform-card ${selectedPlatforms.has('Android') ? 'selected' : ''}`}
            onClick={() => handlePlatformClick('Android')}
          >
            <div className="platform-card-content">
              <AndroidOutlined 
                className="platform-icon" 
                style={{ 
                  fontSize: '48px', 
                  color: '#3DDC84', 
                  stroke: '#3DDC84',
                  strokeWidth: 0.5 
                }} 
              />
              <Meta title="Android" description={`Devices: ${deviceCounts.Android || 0}`} className="platform-meta" />
            </div>
          </Card>
        </Col>
      </Row>
      {/* Componente de búsqueda */}
      <Search
        placeholder="Search devices..."
        onSearch={value => setSearchText(value)}
        onChange={e => setSearchText(e.target.value)}
        style={{ marginBottom: '24px', width: '300px' }}
      />
      {/* Tabla de dispositivos */}
      <Table
        columns={columns}
        dataSource={filteredDevices}
        rowKey="_id"
      />
      {/* Modal de edición */}
      <Modal
        title="Edit Device"
        open={isModalOpen} // Usa `open` en lugar de `visible`
        onCancel={handleCancel}
        onOk={() => form.submit()}
        okText="Save"
        cancelText="Cancel"
      >
        <DeviceEditForm
          form={form}
          initialValues={editingDevice}
          onFinish={handleSave}
        />
      </Modal>
    </div>
  );
};

export default DeviceList;
