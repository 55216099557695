import React, { useState } from 'react';
import { Layout } from 'antd';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import LoginPage from './components/LoginPage';
import AppHeader from './components/AppHeader';
import Sidebar from './components/Sidebar';
import ProfilePage from './components/ProfilePage';
import Home from './components/Home';

import DeviceList from './components/Devices/DeviceList';
import DeviceConfigList from './components/DeviceConfigs/DeviceConfigList';
import EventList from './components/Events/EventList';

import DocDevices from './components/ApiDocs/DocDevices';
import DocConfigs from './components/ApiDocs/DocConfigs';
import DocUsers from './components/ApiDocs/DocUsers';

import SlackNotifications from './components/SlackNotifications';

const { Content: AntContent, Header } = Layout;

const App = () => {
  const { user, isLoading } = useAuth();
  const isAuthenticated = !!user;
  const [collapsed, setCollapsed] = useState(false); // Estado para manejar la colapsación del sidebar
  const [darkTheme, setDarkTheme] = useState(false); // Estado para manejar el tema oscuro

  const handleCollapseToggle = () => {
    setCollapsed(!collapsed); // Alterna el estado de colapsación
  };

  const toggleTheme = () => {
    setDarkTheme(!darkTheme); // Alterna el tema claro/oscuro
  };

  // Mostrar un indicador de carga mientras se verifica el estado de autenticación
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        {/* Ruta de Login fuera del Layout */}
        <Route path="/login" element={!isAuthenticated ? <LoginPage /> : <Navigate to="/" />} />
        
        {/* Rutas protegidas con Layout */}
        <Route
          path="*"
          element={
            isAuthenticated ? (
              <Layout style={{ minHeight: '100vh', backgroundColor: darkTheme ? '#1f1f1f' : '#ffffff' }}>
                <Sidebar 
                  collapsed={collapsed} 
                  toggleCollapse={handleCollapseToggle} 
                  toggleTheme={toggleTheme} 
                  darkTheme={darkTheme} 
                />
                <Layout
                  style={{
                    transition: 'margin-left 0.3s',
                    backgroundColor: darkTheme ? '#1f1f1f' : '#ffffff',
                    marginLeft: collapsed ? '0px' : '0px', // Ajuste para el ancho del sidebar
                  }}
                >
                  <Header style={{ backgroundColor: darkTheme ? '#141414' : '#ffffff', padding: '0 24px', zIndex: 1 }}>
                    <AppHeader />
                  </Header>
                  <AntContent
                    style={{
                      padding: '24px',
                      backgroundColor: darkTheme ? '#141414' : '#ffffff',
                      color: darkTheme ? '#ffffff' : '#000000',
                      flex: 1,
                    }}
                  >
                    <Routes>
                      <Route path="/devices" element={<DeviceList />} />
                      <Route path="/deviceconfigs" element={<DeviceConfigList />} />
                      <Route path="/events" element={< EventList />} />
                      <Route path="/profile" element={<ProfilePage />} />
                      <Route path="/" element={<Home />} />
                      <Route path="/api-docs/devices" element={<DocDevices />} />
                      <Route path="/api-docs/configs" element={<DocConfigs />} />
                      <Route path="/api-docs/users" element={<DocUsers />} />
                      <Route path="/slack-notifications/:id" element={<SlackNotifications />} />
                      <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                  </AntContent>
                </Layout>
              </Layout>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
