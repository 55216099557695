import React from 'react';
import { Form, Input, Select } from 'antd';

const { Option } = Select;

const DeviceEditForm = ({ form, initialValues, onFinish }) => {
  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      layout="vertical"
    >
      <Form.Item
        name="Name"
        label="Name"
        rules={[{ required: true, message: 'Por favor ingrese el nombre del dispositivo' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="SerialNumber"
        label="Serial Number"
        rules={[{ required: true, message: 'Por favor ingrese el número de serie' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="Platform"
        label="Platform"
        rules={[{ required: true, message: 'Por favor seleccione una plataforma' }]}
      >
        <Select>
          <Option value="iOS">iOS</Option>
          <Option value="Windows">Windows</Option>
          <Option value="Android">Android</Option>
          <Option value="MacOS">MacOS</Option>
          <Option value="iPadOS">iPadOS</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="VersionString"
        label="Version"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="Manufacturer"
        label="Manufacturer"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="Idiom"
        label="Idiom"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="Model"
        label="Model"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="Type"
        label="Type"
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

export default DeviceEditForm;
