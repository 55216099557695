import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Row, Col } from 'antd';
import { SlackOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // Asegúrate de que la ruta sea correcta
import axios from '../config/axiosInstance'; // Asegúrate de que la ruta sea correcta

const ProfilePage = () => {
  const { user, setUser } = useAuth();
  const [form] = Form.useForm();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        username: user.username,
        firstname: user.firstname,
        lastname: user.lastname,
        slackID: user.slackID || '', // Asignar slackID si existe
      });
    }
  }, [user, form]);

  const onFinish = async (values) => {
    console.log('Success:', values);
    console.log('Current user:', user);

    try {
      if (user && user._id) {
        // Actualiza el perfil del usuario existente
        const response = await axios.put(`/users/${user._id}`, values);
        const updatedUser = response.data;
        setUser(updatedUser);
        message.success('Profile updated successfully!');
      } else {
        // Crea un nuevo usuario
        const response = await axios.post('/users', values);
        const newUser = response.data;
        setUser(newUser);
        message.success('User created successfully!');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('Error updating profile');
    }

    setIsFormChanged(false);
    navigate('/');
  };

  const onFieldsChange = () => {
    setIsFormChanged(true);
  };

  return (
    <div>
      <h2>{user ? 'Edit Profile' : 'Add New User'}</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="username"
              label="Username"
              rules={[
                { required: !user, message: 'Please input your username!' }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: !user, message: 'Please input your password!' }
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="firstname"
              label="First Name"
              rules={[
                { required: !user, message: 'Please input your first name!' }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lastname"
              label="Last Name"
              rules={[
                { required: !user, message: 'Please input your last name!' }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} align="middle">
          <Col span={4}>
            <Form.Item
              name="slackID"
              label={
                <span>
                  <SlackOutlined style={{ fontSize: '18px', color: '#611f69', marginRight: '5px' }} />
                  Slack ID
                </span>
              }
              rules={[{ required: false, message: 'Please input your Slack ID!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={!isFormChanged}>
            Save
          </Button>
          <Button
            style={{ marginLeft: '10px' }}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ProfilePage;
