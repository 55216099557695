import React, { useState } from 'react';
import { Layout, Menu, Button } from 'antd';
import { 
  MobileTwoTone, 
  SettingTwoTone, 
  QrcodeOutlined, 
  LeftOutlined, 
  RightOutlined,
  BulbOutlined,
  MoonOutlined,
  HomeTwoTone,
  BookTwoTone,
} from '@ant-design/icons'; 
import { Link, useLocation } from 'react-router-dom'; 

const { Sider } = Layout;

const Sidebar = ({ collapsed, toggleCollapse, toggleTheme, darkTheme }) => {
  const location = useLocation();
  const [showButton, setShowButton] = useState(false);

  // Definir los elementos del menú principal
  const menuItems = [
    {
      key: '/home',
      icon: <HomeTwoTone style={{ fontSize: '20px' }} />,
      label: <Link to="/home" style={{ fontSize: '16px' }}>Home</Link>,
    },
    {
      key: '/devices',
      icon: <MobileTwoTone style={{ fontSize: '18px' }} />,
      label: <Link to="/devices" style={{ fontSize: '16px' }}>Devices</Link>,
    },
    {
      key: '/deviceconfigs',
      icon: <SettingTwoTone style={{ fontSize: '18px' }} />,
      label: <Link to="/deviceconfigs" style={{ fontSize: '16px' }}>Device Config</Link>,
    },
    {
      key: '/events',
      icon: <BookTwoTone style={{ fontSize: '18px' }} />,
      label: <Link to="/events" style={{ fontSize: '16px' }}>Events</Link>,
    },
  ];

  // Definir los elementos del menú "API Doc"
  const apiDocItems = [
    {
      key: '/api-docs/devices',
      label: <Link to="/api-docs/devices">Devices Endpoints</Link>,
    },
    {
      key: '/api-docs/configs',
      label: <Link to="/api-docs/configs">Configs Endpoints</Link>,
    },
    {
      key: '/api-docs/users',
      label: <Link to="/api-docs/users">Users Endpoints</Link>,
    },
  ];

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={toggleCollapse}
      trigger={null}
      width={256}
      collapsedWidth={80}
      style={{
        backgroundColor: darkTheme ? '#141414' : '#ffffff',
        borderRight: '1px solid #e0e0e0',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 2,
        height: '100vh', // Asegura que el Sider tenga una altura completa de la ventana
      }}
      onMouseEnter={() => setShowButton(true)}
      onMouseLeave={() => setShowButton(false)}
    >
      {showButton && (
        <Button
          type="link"
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          onClick={toggleCollapse}
          style={{
            position: 'fixed',
            top: 20,
            left: collapsed ? 80 : 256,
            transform: 'translateX(-50%)',
            borderRadius: '50%',
            backgroundColor: darkTheme ? '#141414' : '#ffffff',
            border: '1px solid #e0e0e0',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            zIndex: 3,
            transition: 'opacity 0.3s',
          }}
        />
      )}
      <div style={{ 
        display: 'flex', 
        alignItems: 'center', 
        padding: '20px',
        paddingLeft: '26px',
        backgroundColor: darkTheme ? '#141414' : '#ffffff', 
        fontSize: '24px', 
        fontWeight: 'bold',
        color: darkTheme ? '#ffffff' : '#000000',
      }}>
        <QrcodeOutlined style={{ fontSize: '32px', marginRight: '10px', color: '#1890ff' }} />
        {!collapsed && 'Reactfast'}
      </div>
      <Menu
        theme={darkTheme ? "dark" : "light"}
        mode="inline"
        selectedKeys={[location.pathname]}
        style={{ 
          borderInlineEnd: 'none', 
          marginTop: '16px', 
          flex: 1, 
        }}
        items={menuItems}  
      />
      {/* Separador entre los menús */}
      <div style={{ 
        height: '1px', 
        backgroundColor: darkTheme ? '#e0e0e0' : '#e0e0e0', 
        margin: '16px 0',
        borderTop: '1px solid #e0e0e0' 
      }}></div>
      <Menu
        theme={darkTheme ? "dark" : "light"}
        mode="inline"
        selectedKeys={[location.pathname]}
        style={{ 
          borderInlineEnd: 'none', 
          marginTop: '16px', 
          flex: 1, 
        }}
        items={apiDocItems}  
      />
      <div style={{ 
        height: '1px', 
        backgroundColor: darkTheme ? '#e0e0e0' : '#e0e0e0',
        margin: '16px 0',
        borderTop: '1px solid #e0e0e0'
      }}></div>
      <div style={{
        position: 'absolute',
        bottom: '20px',
        width: '100%',
        textAlign: 'center',
      }}>
        <Button 
          type="primary" 
          icon={darkTheme ? <BulbOutlined /> : <MoonOutlined />} 
          onClick={toggleTheme}
          style={{
            backgroundColor: darkTheme ? '#1c1c1f' : '#ffffff',
            color: darkTheme ? '#ffffff' : '#000000',
            borderColor: darkTheme ? '#1890ff' : '#e0e0e0'
          }}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
