// src/config/axiosInstance.js
import axios from 'axios';
import BASE_URL from '../config'; // Ajusta la ruta según sea necesario

// Crea una instancia de axios configurada
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    // El token se configurará dinámicamente en cada solicitud
    'Content-Type': 'application/json',
  }
});

// Interceptor para agregar el token a las solicitudes
axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token'); // Obtén el token del almacenamiento local
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Agrega el token a las cabeceras
    }
    return config;
  },
  error => Promise.reject(error)
);

export default axiosInstance;
