import React, { useState, useEffect } from 'react';
import { Typography, Card, Progress, Row, Col, Statistic } from 'antd';
import { 
  MobileOutlined, 
  SettingOutlined, 
  QrcodeOutlined, 
  MobileTwoTone
} from '@ant-design/icons'; 
import axios from '../config/axiosInstance';

const { Title } = Typography;

const Home = () => {
  const [devices, setDevices] = useState([]);
  const [configuredPercentage, setConfiguredPercentage] = useState(0);
  const [configuredCount, setConfiguredCount] = useState(0);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await axios.get('/devices');
        const data = response.data;
        setDevices(data);

        // Calcula el número y porcentaje de dispositivos configurados
        const configuredDevices = data.filter(device => device.DeviceConfigId);
        const percentage = Math.round((configuredDevices.length / data.length) * 100);
        setConfiguredPercentage(percentage);
        setConfiguredCount(configuredDevices.length);
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    };

    fetchDevices();
  }, []);

  const progressWrapperStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  return (
    <div style={{ padding: 24, minHeight: 280 }}>
      <Title level={2} style={{ marginBottom: '40px' }}>
        Welcome to ReactFast Dashboard
      </Title>
      <Row gutter={[16, 16]} >
        <Col xs={24} sm={12} md={8} lg={6}>
          <Card 
            bordered={false} 
            hoverable={true}
            title={
              <div 
                style={{ 
                color: '#1B8AF1',
              }}>
                <SettingOutlined style={{ marginRight: '10px', color: '#1B8AF1' }} />
                Devices Configuration
              </div>
            }
          >
            <div style={progressWrapperStyle}>
              <Progress 
                type="circle" 
                percent={configuredPercentage} 
                format={percent => `${percent}%`} 
                strokeColor="#1B8AF1" // Color del progreso
              />
              <Statistic 
                value={`${configuredCount}/${devices.length}`} 
                valueStyle={{ marginTop: '10px' }} 
                suffix="Configured"
              />
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={4}>
          <Card 
            bordered={false} 
            hoverable={true}
            title={
              <div 
                style={{ 
                  color: '#1B8AF1',
                }}>
                <MobileOutlined style={{ marginRight: '10px', color: '#1B8AF1' }} />
                Total Devices
              </div>
            }
          >
            <div style={progressWrapperStyle}>
              <Statistic 
                value={devices.length} 
                valueStyle={{ fontSize: '30px' }} 
                prefix={<MobileTwoTone />} 
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
