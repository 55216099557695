import React, { useState } from 'react';
import { Form, Input, Button, message, Flex } from 'antd';
import { QrcodeOutlined } from '@ant-design/icons';
import axios from '../config/axiosInstance';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const response = await axios.post(`/auth/login`, values);
      const { token } = response.data;

      if (token) {
        login(token);
        message.success('Login successful!');
        navigate('/'); // Redirige al home después del login exitoso
      } else {
        message.error('Login failed. No token received.');
      }
    } catch (error) {
      console.error('Login error:', error.response ? error.response.data : error.message);
      message.error('Login failed. Please try again.');
    }
    setLoading(false);
  };

  const wrapperStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    background: 'linear-gradient(135deg, #ffffff 50%, rgba(255, 255, 255, 0) 50%), linear-gradient(135deg, #1B8AF1, #B58DED)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
  };

  const loginStyle = {
    backgroundColor: 'white',
    width: '434px',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    textAlign: 'center',
  };

  const textAreaStyle = {
    marginBottom: '35px',
    width: '80%',
    height: '50px',
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.2), 0 0 20px 0 rgba(0, 0, 0, 0.19)',
    borderRadius: '10px',
    backgroundColor: 'transparent',
    display: 'inline-flex',
  };

  const textInputStyle = {
    marginTop: '0.2rem',
    fontSize: '20px',
    border: 'none',
    width: '90%',
    paddingLeft: '50px',
    backgroundColor: 'transparent',
  };

  const buttonStyle = {
    marginTop: '30px',
    marginBottom: '30px',
    width: '140px',
    height: '60px',
    borderRadius: '10px',
    background: '#1B8AF1',
    border: 'none',
    fontSize: '20px',
    color: '#FFFFFF',
    cursor: 'pointer',
  };

  return (
    <div style={wrapperStyle}>
      <div style={loginStyle}>
        <h4 style={{ fontSize: '32px' }}>
          <QrcodeOutlined style={{ fontSize: '32px', marginRight: '10px', color: '#1890ff' }} />
          Reactfast
        </h4>
        <Form
          name="login"
          onFinish={onFinish}
          initialValues={{ remember: true }}
          style={{ width: '100%' }}
        >
          <div style={textAreaStyle}>
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'Please input your username!' }]}
              style={{ marginBottom: 0 }}
            >
              <Input
                style={textInputStyle}
                placeholder="Username"
                autoComplete="username"
              />
            </Form.Item>
          </div>

          <div style={textAreaStyle}>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
              style={{ marginBottom: 0 }}
            >
              <Input.Password
                style={textInputStyle}
                placeholder="Password"
                autoComplete="current-password"
              />
            </Form.Item>
          </div>

          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              type="primary"
              htmlType="submit"
              style={buttonStyle}
              loading={loading}
            >
              LOGIN
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginPage;
