import React from 'react';
import { Modal, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const DeviceConfigPreview = ({ open, onCancel, deviceConfig }) => {
  if (!deviceConfig) {
    return (
      <Modal
        title="Device Config Preview"
        open={open}
        onCancel={onCancel}
        footer={null}
        width={1100}  // Hacemos el modal más ancho
        style={{ top: 20 }}
      >
        <div style={{
          height: '600px',
          width: '100%',
          border: '1px solid #e0e0e0',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
          backgroundColor: '#ffffff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <h2>No Device Config Selected</h2>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      title={null}  // Ocultamos el título del modal para darle más realismo
      open={open}
      onCancel={onCancel}
      footer={null}
      width={1200} // Ancho del modal para dejar espacio a los lados
      style={{ top: 20, padding: 0 }}
      bodyStyle={{
        padding: '20px 30px',  // Espacio alrededor del Surface
        margin: 0,
        backgroundColor: '#ffffff',  // Color de fondo gris claro
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative'
      }}
    >
      <div style={{
        width: '70%',  // El Surface es más pequeño y centrado
        height: '600px',
        backgroundColor: '#ffffff',
        border: '20px solid black',  // Bordes negros para imitar el bisel del Surface
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        margin: '0 auto',  // Centrar el Surface
      }}>
        {/* Parte superior con la imagen del banner */}
        {deviceConfig.BannerUrl && (
          <div style={{
            width: '100%',
            height: '100px',
            marginTop: '4px',
            backgroundImage: `url(${deviceConfig.BannerUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '20px 20px 0 0',  // Solo los bordes superiores redondeados
          }} />
        )}
      </div>

      {/* Botones de retroceso y avance */}
      <div style={{
        marginTop: '20px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <Button type="primary" icon={<LeftOutlined />} />
        <Button type="primary" icon={<RightOutlined />} />
      </div>
    </Modal>
  );
};

export default DeviceConfigPreview;
