import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Button, Input, message as antdMessage, Popconfirm, Row, Col, Modal, Checkbox, Card, List } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined, WarningOutlined, SlackOutlined, UsergroupAddOutlined, MessageOutlined } from '@ant-design/icons';
import axios from '../config/axiosInstance';

const { Title } = Typography;

const SlackNotifications = () => {
  const { id } = useParams(); // ID del DeviceConfig
  const [channelId, setChannelId] = useState(null);
  const [channelName, setChannelName] = useState('');
  const [channelUrl, setChannelUrl] = useState('');
  const [isChannelNameValid, setIsChannelNameValid] = useState(false);
  const [message, setMessage] = useState('');
  const [userIds, setUserIds] = useState([]); // IDs de los usuarios que se añadirán al canal
  const [users, setUsers] = useState([]); // Lista de usuarios
  const [selectedUserIds, setSelectedUserIds] = useState([]); // IDs de usuarios seleccionados
  const [modalVisible, setModalVisible] = useState(false); // Controlar la visibilidad del modal
  const [recentMessages, setRecentMessages] = useState([]); // Estado para almacenar los últimos mensajes

  useEffect(() => {
    const fetchDeviceConfig = async () => {
      try {
        const response = await axios.get(`/deviceConfigs/${id}`);
        const deviceConfigData = response.data;
        
        if (deviceConfigData.SlackChannel) {
          const slackChannelId = deviceConfigData.SlackChannel;
          
          const slackResponse = await axios.get(`/slack/slackChannels/${slackChannelId}`);
          const slackChannelData = slackResponse.data;
  
          setChannelId(slackChannelData._id);
          setChannelName(slackChannelData.SlackChannelName || '');
          setChannelUrl(slackChannelData.SlackChannelURL || '');
          validateChannelName(slackChannelData.SlackChannelName || '');
  
          // Configurar los IDs de los usuarios que ya están en el canal
          const userIds = slackChannelData.users.map(user => user._id);
          setSelectedUserIds(userIds);
        }
  
      } catch (error) {
        console.error('Error fetching DeviceConfig:', error);
        antdMessage.error('Error fetching DeviceConfig');
      }
    };
  
    fetchDeviceConfig();
  }, [id]);

  // Obtener los últimos mensajes del canal
  useEffect(() => {
    if (channelUrl) {
      fetchRecentMessages(channelUrl);
    }
  }, [channelUrl]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`/users`);
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
        antdMessage.error('Error fetching users');
      }
    };
  
    fetchUsers();
  }, []);

  const fetchRecentMessages = async (channelId) => {
    try {
      const extractedChannelId = extractChannelIdFromUrl(channelUrl);
      console.log('extractedChannelId:', extractedChannelId)

      const response = await axios.get(`/slack/channel-messages/${extractedChannelId}`);
      setRecentMessages(response.data.messages); // Asegúrate de que la respuesta del backend esté en este formato
    } catch (error) {
      console.error('Error fetching recent messages:', error);
      antdMessage.error('Error fetching recent messages');
    }
  };

  const iconForType = (type) => {
    switch (type) {
      case 'success':
        return <CheckCircleOutlined style={{ color: 'green' }} />;
      case 'error':
        return <ExclamationCircleOutlined style={{ color: 'red' }} />;
      case 'warning':
        return <WarningOutlined style={{ color: 'orange' }} />;
      default:
        return null;
    }
  };

  const validateChannelName = (name) => {
    const isValid = /^[a-z0-9-_]{1,80}$/.test(name); // Reglas de validación de Slack
    setIsChannelNameValid(isValid);
  };

  const handleChannelNameChange = (e) => {
    const name = e.target.value.toLowerCase();
    setChannelName(name);
    validateChannelName(name);
  };

  const createChannel = async () => {
    if (!isChannelNameValid) {
      antdMessage.error('The channel name is invalid');
      return;
    }

    try {
      const response = await axios.post(`/slack/create-channel`, {
        name: channelName,
        isPrivate: true,
        deviceConfigId: id,
        userIds: userIds, // Enviamos los IDs de los usuarios que se asociarán al canal
      });

      if (response.data.success) {
        setChannelId(response.data.channelId);
        setChannelUrl(response.data.channelUrl); // Actualiza la URL del canal
        antdMessage.success(`Channel created: ${channelName}`);

        // Obtener los últimos mensajes del nuevo canal
        fetchRecentMessages(response.data.channelId);
      } else {
        antdMessage.error(`Error creating channel: ${response.data.error}`);
      }
    } catch (error) {
      console.error('Error creating channel:', error);
      antdMessage.error('Error creating channel');
    }
  };

  const confirmCreateNewChannel = () => {
    createChannel();
  };

  const sendMessage = async () => {
    if (!channelId && !channelUrl) {
      antdMessage.error('You must create a channel first');
      return;
    }

    try {
      const response = await axios.post(`/slack/send-message`, {
        message: message,
        deviceConfigId: id,
      });

      if (response.data.success) {
        antdMessage.success('Message sent to the channel');
        setMessage(''); // Limpiar el campo de entrada

        // Obtener los últimos mensajes después de enviar uno
        fetchRecentMessages(channelId);
      } else {
        antdMessage.error('Error sending message');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      antdMessage.error('Error sending message');
    }
  };

  const handleUserSelection = (userId) => {
    setSelectedUserIds((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter(id => id !== userId)
        : [...prevSelected, userId]
    );
  };

  const extractChannelIdFromUrl = (url) => {
    const match = url.match(/\/archives\/([^/]+)$/);
    return match ? match[1] : null;
  };

  const handleAddUsers = async () => {
    try {
      const extractedChannelId = extractChannelIdFromUrl(channelUrl);
  
      if (!extractedChannelId) {
        antdMessage.error('The channel ID could not be extracted from the URL');
        return;
      }

      console.log('Selected User IDs:', selectedUserIds);
  
      const response = await axios.post(`/slack/invite-users`, {
        channelId: extractedChannelId,
        userIds: selectedUserIds, // Envía _id de los usuarios
      });
  
      if (response.data.success) {
        antdMessage.success('Users added to the channel.');
        setModalVisible(false);
      } else {
        if (response.data.error === 'User already in channel') {
          antdMessage.warning('One or more users are already in the channel.');
        } else {
          antdMessage.error(`Error adding users to the channel: ${response.data.details || 'Unknown error'}`);
        }
      }
    } catch (error) {
      // Comprobar si el error tiene una respuesta y manejar el mensaje de error adecuadamente
      if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error === 'User already in channel') {
          antdMessage.warning('One or more users are already in the channel.');
        } else {
          antdMessage.error(`Error adding users to the channel: ${error.response.data.details || 'Unknown error'}`);
        }
      } else {
        antdMessage.error('Error adding users to the channel.');
      }
      console.error('Error adding users:', error);
      
    }
  };

  return (
    <div>
      <Card
        style={{ backgroundColor: '#f3e5f5', padding: '20px' }} // Fondo morado claro
      >
        <Row align="middle" style={{ marginBottom: '20px' }}>
          <SlackOutlined style={{ fontSize: '32px', marginRight: '10px', color: '#611f69' }} />
          <Title level={2} style={{ margin: 0 }}>Slack Notifications</Title>
        </Row>

        <Row gutter={16}>
          {/* Left Column */}
          <Col span={12}>
            <Row gutter={[16, 16]}>
              {/* Row 1: Title */}
              <Col span={24}>
                <Title level={4} style={{ marginTop: 0 }}>Slack Channel</Title>
              </Col>

              {/* Row 2: Inputs */}
              <Col span={10}>
                <Input
                  value={channelName}
                  onChange={handleChannelNameChange}
                  placeholder="Enter the channel name"
                  style={{ marginBottom: '10px' }}
                />
                {!isChannelNameValid && channelName && (
                  <div style={{ color: 'red', marginBottom: '10px' }}>
                    Invalid channel name. Must contain only lowercase letters, numbers, hyphens (-) and underscores (_).
                  </div>
                )}
              </Col>
              <Col span={14}>
                {channelUrl && (
                  <Input
                    value={channelUrl}
                    readOnly
                    style={{ marginBottom: '10px' }}
                  />
                )}
              </Col>

              {/* Row 3: Buttons */}
              <Col span={24}>
                <Row gutter={16}>
                  <Col>
                    {channelName && channelUrl ? (
                      <Popconfirm
                        title="Are you sure you want to create a new channel and overwrite the existing one?"
                        onConfirm={confirmCreateNewChannel}
                        okText="Yes"
                        placement="right"
                        cancelText="No"
                      >
                        <Button
                          type="primary"
                          disabled={!isChannelNameValid}
                          style={{
                            backgroundColor: '#611f69',
                            color: '#fff',
                            borderColor: '#611f69',
                          }}
                        >
                          Create Channel
                        </Button>
                      </Popconfirm>
                    ) : (
                      <Button
                        type="primary"
                        onClick={createChannel}
                        disabled={!isChannelNameValid || !!channelId}
                      >
                        Create Channel
                      </Button>
                    )}
                  </Col>
                  <Col>
                    {channelUrl && (
                      <Button
                        icon={<UsergroupAddOutlined />}
                        onClick={() => setModalVisible(true)}
                        style={{
                          backgroundColor: '#611f69',
                          color: '#fff',
                          borderColor: '#611f69',
                        }}
                      >
                        Add Users
                      </Button>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {/* Right Column */}
          <Col span={12}>
            {/* Row 1: Título */}
            <Row align="middle" style={{ marginBottom: '20px' }}>
              <MessageOutlined style={{ fontSize: '22px', marginRight: '10px', color: '#611f69' }} />
              <Title level={4} style={{ margin: 0 }}>Messenger</Title>
            </Row>

            {/* Row 2: Input */}
            <Row>
              <Col span={24}>
                <Input.TextArea
                  rows={4}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Write a message to send to Slack channel"
                  style={{ marginBottom: '10px' }}
                  disabled={!channelUrl} // Desactiva si no existe un canal
                />
              </Col>
            </Row>

            {/* Row 3: Botón */}
            <Row>
              <Col span={24}>
                <Button
                  type="primary"
                  onClick={sendMessage}
                  style={{
                    backgroundColor: '#611f69',
                    color: '#fff',
                    borderColor: '#611f69',
                    marginTop: '10px'
                  }}
                  disabled={!channelUrl} // Desactiva si no existe un canal
                >
                  Send a Message
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      {/* Nuevo Card para mostrar los últimos mensajes */}
      <Card
        style={{ backgroundColor: '#f3e5f5', padding: '20px', marginTop: '20px' }} // Fondo morado claro
      >
        <Row align="middle" style={{ marginBottom: '20px' }}>
          <MessageOutlined style={{ fontSize: '32px', marginRight: '10px', color: '#611f69' }} />
          <Title level={2} style={{ margin: 0 }}>Recent Messages</Title>
        </Row>

        {/* Aquí se mostrarán los últimos mensajes */}
        <List
          bordered
          dataSource={recentMessages}
          renderItem={item => (
            <List.Item>
              <Typography.Text strong>{item.user}:</Typography.Text> {item.text}
            </List.Item>
          )}
          style={{
            backgroundColor: '#ffffff',
            padding: '10px',
            borderRadius: '5px',
            maxHeight: '300px',
            overflowY: 'scroll',
          }}
        />
      </Card>

      {/* Modal para agregar usuarios */}
      <Modal
        title={
          <span style={{ fontSize: '24px', fontWeight: 'bold' }}>
            <UsergroupAddOutlined style={{ marginRight: '10px' }} />
            Add Users to Channel
          </span>
        }
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleAddUsers}>
            Add to Channel
          </Button>
        ]}
      >
        {users.map((user) => (
          <div key={user._id}>
            <Checkbox
              checked={selectedUserIds.includes(user._id)}
              onChange={() => handleUserSelection(user._id)}
              disabled={!user.slackID || user.slackID.trim() === ''}
            >
              {user.firstname} {user.lastname}
            </Checkbox>
            <br />
          </div>
        ))}
      </Modal>
    </div>
  );
};

export default SlackNotifications;
